import React, { useEffect, useState, useContext } from "react";
import { CheckTreePicker, Sidebar, Sidenav } from "rsuite";
import { FiCopy } from "react-icons/fi";
// import { visualization } from "../../../utils/reports/dataSource";
import { getAllWidgets } from "src/common/analytics-and-dashboard-api-calls";
import "./sidebarComponentReport.scss";
import { ReportContextAPI } from "src/pages/CreateReport";

function renderTreeNode(node: any) {
  const labelStyle = node.children ? { fontWeight: "bold" } : {};
  return <span style={labelStyle}>{node.label}</span>;
}

// const visualizationChangeHandler = (value: any) => {};

const SideNavigation = () => {
  const { setReportWidgetSelectedList } = useContext(ReportContextAPI);

  const [totalWidgets, setTotalWidgets] = useState([]);
  const [dataSourceOptions, setDataSourceOptions] = useState([]);

  const dataSourceChangeHandler = (value: (string | number)[]) => {
    let selectedReport: number[] = [];
    value.length > 0 &&
      value.map((data) => {
        if (typeof data === "string") {
          totalWidgets.map((value: any) => {
            if (value.widget_tags === data.toLocaleLowerCase()) {
              selectedReport.push(value.id);
            }
            return value;
          });
        } else {
          selectedReport.push(data);
        }
        return data;
      });
    setReportWidgetSelectedList(selectedReport);
  };

  function findDefaultValues(data: any): number[] {
    const values: number[] = [];

    function traverse(node: any) {
      if (node.value) {
        values.push(node.value);
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => {
          traverse(child);
        });
      }
    }

    data.forEach((item: any) => {
      item.children.forEach((child: any) => {
        traverse(child);
      });
    });

    return values;
  }

  const processSourceTreeData = (data: any) => {
    const transformedData: any = [];
    const widgetCategoryMap: any = {};

    data.forEach((widget: any) => {
      const category = widget.widget_tags;
      if (!(category in widgetCategoryMap)) {
        widgetCategoryMap[category] =
          category.charAt(0).toUpperCase() + category.slice(1);
      }
    });

    const widgetTypeMap: any = {};

    data.forEach((widget: any) => {
      const type = widget.widget_format;
      if (!(type in widgetTypeMap)) {
        widgetTypeMap[type] = type
          .split("_")
          .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
    });

    data.forEach((widget: any) => {
      const category =
        widgetCategoryMap[widget.widget_tags] || widget.widget_tags;
      const type = widgetTypeMap[widget.widget_format] || widget.widget_format;
      const existingCategory = transformedData.find(
        (item: any) => item.value === category
      );
      if (existingCategory) {
        const existingType = existingCategory.children.find(
          (item: any) => item.value === `${category}.${type}`
        );
        if (existingType) {
          existingType.children.push({
            label: widget.widget_title,
            value: widget.id,
          });
        } else {
          existingCategory.children.push({
            label: type,
            value: `${category}.${type}`,
            children: [
              {
                label: widget.widget_title,
                value: widget.id,
              },
            ],
          });
        }
      } else {
        transformedData.push({
          label: category,
          value: category,
          children: [
            {
              label: type,
              value: `${category}.${type}`,
              children: [
                {
                  label: widget.widget_title,
                  value: widget.id,
                },
              ],
            },
          ],
        });
      }
    });

    // Flatten the children
    transformedData.forEach((category: any) => {
      category.children = category.children.reduce((arr: any[], item: any) => {
        return [...arr, ...item.children];
      }, []);
    });
    const _dataSourceKeys = findDefaultValues(transformedData);
    setReportWidgetSelectedList(_dataSourceKeys);
    setDataSourceOptions(transformedData);
  };

  useEffect(() => {
    const fetchAllWidgetsList = async () => {
      const response = await getAllWidgets();
      if (response.error === true) {
      } else {
        setTotalWidgets(response.data);
        processSourceTreeData(response.data);
      }
    };
    fetchAllWidgetsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Sidenav className="sidenav__create_report" defaultOpenKeys={["1", "2"]}>
        <Sidenav.Body>
          <div className="parent_container">
            <div className="search_container">
              <div className="title">
                1-{totalWidgets.length} of {totalWidgets.length} results
              </div>
              {/* <div className="input">
                <input type="text" placeholder="Search" />
              </div> */}
            </div>

            <div className="filter_container">
              <div className="filter_header">
                <div className="icon">
                  <FiCopy />
                </div>
                <div className="title">Filter templates</div>
              </div>

              <div className="data_source">
                <div className="title">Data source</div>
                <CheckTreePicker
                  data={dataSourceOptions}
                  renderTreeNode={renderTreeNode}
                  onChange={dataSourceChangeHandler}
                  className="checkbox_tree"
                  placeholder="Filter Your Reports"
                  size={"lg"}
                  cleanable={false}
                  defaultExpandAll={true}
                  countable={false}
                ></CheckTreePicker>
              </div>

              {/* <div className="Visualization">
                <div className="title">Visualization</div>
                <CheckTreePicker
                  data={visualization}
                  renderTreeNode={(node) => {
                    return (
                      <div className="showIconText">
                        <img src={node.icon} alt="" /> {node.label}
                      </div>
                    );
                  }}
                  defaultExpandAll={true}
                  className="checkbox_tree"
                  placeholder="All Data"
                  onChange={visualizationChangeHandler}
                  size={"lg"}
                ></CheckTreePicker>
              </div> */}
            </div>
          </div>
        </Sidenav.Body>
      </Sidenav>
    </>
  );
};

const SidebarComponentReport: React.FC = () => {
  return (
    <>
      <Sidebar className="main-sidenav">
        <SideNavigation />
      </Sidebar>
    </>
  );
};

export default SidebarComponentReport;
