export const registeredWidgets = [
  "contact_vs_recent",
  "contact_vs_time",
  "contact_creation_total_by_source",
  "contact_creation_total_by_person",
  "company_vs_recent",
  "total_ticket_created_vs_last_week",
  "company_vs_time",
  "company_creation_total_by_person",
  "ticket_vs_month",
  "ticket_status",
  "get_stage_ticket_count",
  "get_created_last_24h",
  "get_close_last_24h",
  "deals_in_a_stage_count",
  "total_company_created",
  "last_week_company_created",
  "top_month_company_creation",
  "average_company_creation_per_month",
  "total_task_count",
  "last_week_total_task_count",
  "top_month_task_analytics",
  "avg_month_task_analytics_cards",
  "get_max_deal_amount_month",
  "get_lowest_deal_amount_month",
  "month_wise_deals_amount",
  "get_total_ticket",
  "get_total_open_ticket",
  "get_total_close_ticket",
  "get_total_ticket_in_progress",
  "average_ticket_resolve_time",
  "ticket_close_before",
  "ticket_close_after",
  "ticket_person_wise_in_progress",
  "ticket_person_wise_open",
  "ticket_person_wise_close",
];
