/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, memo, useRef } from "react";
import { registeredWidgets } from "src/widget.register";
import { Responsive, WidthProvider, Layouts, Layout } from "react-grid-layout";
import TemplateRenderer from "src/components/TemplateRenderer/TemplateRenderer";
import { updateRankPositionOfDashboardWidget } from "src/common/analytics-and-dashboard-api-calls";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
  data: any[];
  layouts?: Layouts | any;
  dashboardId: string;
}

const GridLayout: React.FC<GridLayoutProps> = ({
  data,
  layouts: _layout,
  dashboardId,
}) => {
  console.log("_", _layout, _layout.length, data.length);

  const isModified = useRef(false);

  const [cellsToShow, setCellsToShow] = useState(1);

  const [layouts, setLayouts] = useState<Layouts>({});
  const [layoutsIndication, setLayoutsIndication] = useState<Layouts>({});
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [layoutsNew, setLayoutsNew] = useState<Layout[]>([]);

  const generateLayout = useCallback(
    (_: string) => {
      return data.map((_, index) => ({
        i: `layout-item-${index}`,
        x: (index * 6) % 12,
        y: Math.floor(index / 2),
        w: 6,
        h: 4,
        minW: 2,
        minH: 1,
      }));
    },
    [data]
  );

  const generateLayoutForView = useCallback(() => {
    const _ = new Array(cellsToShow).fill("");
    return _.map((_, index) => ({
      i: `layout-item-${index}-${index}`,
      x: (index * 1) % 12,
      y: Math.floor(index / 2),
      w: 1,
      h: 1,
    }));
  }, [cellsToShow]);

  const handleBreakPointChange = useCallback(
    (newBreakpoint: string) => {
      if (_layout && _layout[0].i !== undefined) {
        setLayouts((prevLayouts) => ({
          ...prevLayouts,
          [newBreakpoint]: JSON.parse(JSON.stringify(_layout)),
        }));
      } else {
        const newLayout = generateLayout(newBreakpoint);
        setLayouts((prevLayouts) => ({
          ...prevLayouts,
          [newBreakpoint]: newLayout,
        }));
      }
    },
    [dashboardId, _layout]
  );

  const handleLayoutChange = useCallback(
    async (newLayout: Layout[], _: Layouts, breakpoint: string) => {
      if (isModified.current) {
        await updateRankPositionOfDashboardWidget(
          Number(dashboardId),
          [],
          newLayout
        );
      }
      setLayoutsNew(newLayout);
    },
    [dashboardId]
  );

  // const generateNewLayoutItem = (existingLayouts: any, index: any) => {
  //   const maxY = Math.max(...existingLayouts.map(({ y }: any) => y));
  //   const maxYItems = existingLayouts.filter(({ y }: any) => y === maxY);
  //   const nextX = maxYItems.reduce(
  //     (acc: any, { x, w }: any) => Math.max(acc, x + w),
  //     0
  //   );
  //   const nextY = maxYItems.length > 0 ? maxY : 0;

  //   return {
  //     i: `layout-item-${index}`,
  //     x: nextX,
  //     y: nextY,
  //     w: 6,
  //     h: 4,
  //     minW: 6,
  //     minH: 4,
  //     moved: false,
  //     static: false,
  //   };
  // };

  const onDragStart = useCallback(() => {
    setIsDragging(true);
    isModified.current = true;
  }, []);

  const onDragStop = useCallback(() => {
    setIsDragging(false);
    isModified.current = true;
  }, []);

  useEffect(() => {
    if (dashboardId) {
      const persistedLayouts = Object.keys(layouts).reduce(
        (acc, breakpoint) => {
          if (_layout !== null) {
            acc[breakpoint] = JSON.parse(JSON.stringify(_layout));
          } else {
            const newLayout = generateLayout("lg");
            acc[breakpoint] = newLayout;
          }
          // const newLayout = generateLayout("lg");
          // acc[breakpoint] = newLayout;
          return acc;
        },
        {} as Layouts
      );
      setLayouts(persistedLayouts);
    }
  }, [data, dashboardId, generateLayout, _layout]);

  useEffect(() => {
    const initialLayoutForIndication = generateLayoutForView();
    setLayoutsIndication({ lg: initialLayoutForIndication });
  }, [cellsToShow, generateLayoutForView]);

  useEffect(() => {
    setCellsToShow(data?.length * 6 * 5);

    if (data?.length > layouts?.lg?.length) {
      const newLayouts = data.map((_, index) => {
        const isEven = index % 2 === 0;
        return (
          layouts.lg[index] || {
            h: 4,
            i: `layout-item-${index}`,
            w: 6,
            x: isEven ? 0 : 6,
            y: isEven ? 6 : 0,
            moved: false,
            static: false,
          }
        );
      });

      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        lg: newLayouts,
      }));
    }
    if (data?.length < layouts?.lg?.length) {
      const newLayouts = data.map((_, index) => {
        return layouts.lg[index];
      });

      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        lg: newLayouts,
      }));
    }
  }, [data, layouts?.lg?.length]);

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        onResizeStart={onDragStart}
        onResizeStop={onDragStop}
        onBreakpointChange={handleBreakPointChange}
        onLayoutChange={handleLayoutChange as any}
        draggableHandle=".drag"
        breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
      >
        {data.map((item, index) => {
          return (
            registeredWidgets.includes(item?.widget_name) && (
              <div
                key={`layout-item-${index}`}
                className="grid-item"
                style={{ border: "0.7px solid #D1D8E3", background: "white" }}
              >
                <div
                  className="grid-item__title drag"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <TemplateRenderer
                    item={item}
                    key={item?.widget_name}
                    {...(layoutsNew
                      ? { layoutsPassToTemplate: layoutsNew[index] }
                      : {})}
                  />
                </div>
              </div>
            )
          );
        })}
      </ResponsiveGridLayout>

      {isDragging && (
        <div>
          <div
            className="indication-layout"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <div style={{ flex: 1, zIndex: "-1" }}>
              <ResponsiveGridLayout
                className="guide-grid-inner-layout"
                layouts={layoutsIndication}
                breakpoints={{ lg: 1280 }}
                cols={{ lg: 12 }}
                margin={[10, 10]}
                isDraggable={false}
                isResizable={false}
                useCSSTransforms={true}
              >
                {new Array(cellsToShow).fill("").map((item, index) => (
                  <div
                    key={`layout-item-${index}-${index}`}
                    className="grid-item"
                    style={{ border: "1px solid gray", opacity: ".3" }}
                  ></div>
                ))}
              </ResponsiveGridLayout>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GridLayout);
