import React, { useEffect, useState } from "react";
import Cards from "src/micro/Cards/Cards";

import "./microCardGroup.scss";
import {
  deleteWidgetFromDashboard,
  getCompanyCreationData,
  getContactCreationData,
  getDashboardLists,
  getTotalTicketCreatedByLastDate,
  moveReport,
  renameReport,
} from "src/common/analytics-and-dashboard-api-calls";
import HasError from "src/common/components/hasError/HasError";
import LoadingComponent from "src/common/components/loading/Loading";
import {
  ILayoutsPNew,
  IWidgetInfo,
  analyticsEndpoints,
} from "src/common/types/analytics.types";
import SnippetsHeaderActions from "src/micro/snippets/SnippetsHeaderActions";
import { Button, Divider, Input, Modal, SelectPicker } from "rsuite";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
interface IMicroCardGroup {
  title?: string;
  endpoint?: string;
  description?: string;
  widgetData?: IWidgetInfo;
  layoutsPassToTemplate?: ILayoutsPNew;
}
const MicroCardGroup: React.FC<IMicroCardGroup> = ({
  title,
  endpoint,
  description,
  widgetData,
  layoutsPassToTemplate,
}: IMicroCardGroup) => {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsFilterModalOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(title ?? "");
  const [isModalOpenForOptions, setIsModalOpenForOptions] = useState(false);
  const [optionUpdateBtnDisable, setOptionUpdateBtnDisable] = useState(true);
  const [selectedOption, setSelectedOption] = useState("rename");
  const [hasError, setHasError] = useState(false);
  const [selectedDashBoard, setSelectedDashboard] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [dashboards, setDashboards] = useState([]);

  const getCardData = async () => {
    if (endpoint === analyticsEndpoints.total_contact_created_vs_last_week) {
      const response = await getContactCreationData();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setCardData(response.data);
      }
    }

    if (endpoint === analyticsEndpoints.total_company_created_vs_last_week) {
      const response = await getCompanyCreationData();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setCardData(response.data);
      }
    }

    if (endpoint === analyticsEndpoints.TOTAL_TICKET_CREATED_VS_LAST_WEEK) {
      const response = await getTotalTicketCreatedByLastDate();
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setCardData(response.data);
      }
    }
  };

  const getDashboardInfo = async () => {
    const response = await getDashboardLists();
    if (response.data.length > 0) {
      let modified: any = [];
      response.data.map((data: any) => {
        return modified.push({
          value: data.id,
          label: data.dashboard_name,
        });
      });
      setDashboards(modified);
    }
  };

  const handleWidgetDelete = async () => {
    setIsLoading(true);
    const response = await deleteWidgetFromDashboard(
      id,
      widgetData?.user_widget_association_id
        ? widgetData?.user_widget_association_id
        : 0
    );

    if (response.error === true) {
      setIsLoading(false);
      toast.error("Failed to delete widget from dashboard.");
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleFilterOpeningModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleReportRename = async () => {
    setOptionUpdateBtnDisable(true);
    const response = await renameReport(
      widgetData?.user_widget_association_id as number,
      reportTitle as string
    );

    if (response.error === true) {
      toast.error("Failed to rename report.");
      setIsLoading(false);
      setOptionUpdateBtnDisable(false);
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleReportMove = async () => {
    setOptionUpdateBtnDisable(true);
    const response = await moveReport(
      id,
      widgetData?.user_widget_association_id as number,
      selectedDashBoard
    );

    if (response.error === true) {
      toast.error("Failed to move report.");
      setIsLoading(false);
      setOptionUpdateBtnDisable(false);
    } else {
      window.location.reload();
      setIsLoading(false);
    }
  };

  const handleModalOpenerForOptions = (option: string) => {
    setSelectedOption(option);
    setIsModalOpenForOptions(true);
  };

  useEffect(() => {
    if (selectedOption === "move") {
      getDashboardInfo();
    }
  }, [selectedOption]);

  useEffect(() => {
    getCardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        className="__generic_modal_v2"
        autoFocus
        show={isModalOpenForOptions}
        onHide={() => {
          setIsModalOpenForOptions(false);
        }}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header className="modal-header-custom">
          <Modal.Title>
            {selectedOption === "rename"
              ? `Rename your dashboard`
              : "Move your report"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0 40px 1px", minHeight: "180px" }}>
          {selectedOption === "rename" && (
            <>
              <div className="title_for_rt">Rename this report *</div>
              <Input
                value={reportTitle}
                onChange={(eve) => {
                  if (optionUpdateBtnDisable) {
                    setOptionUpdateBtnDisable(false);
                  }
                  setReportTitle(eve);
                }}
                style={{ marginTop: "10px", padding: "10px" }}
              />
            </>
          )}

          {selectedOption === "move" && (
            <>
              <div className="title_for_rt">Move this report *</div>
              <SelectPicker
                style={{
                  marginTop: "15px",
                  width: "320px",
                }}
                data={dashboards}
                cleanable={false}
                onSelect={(value) => {
                  if (optionUpdateBtnDisable) {
                    setOptionUpdateBtnDisable(false);
                  }
                  setSelectedDashboard(value);
                }}
              />
            </>
          )}

          <Divider />
          <div
            className="actions"
            style={{ display: "flex", gap: "10px", marginBottom: "25px" }}
          >
            <Button
              color="green"
              appearance="default"
              disabled={optionUpdateBtnDisable}
              onClick={() => {
                if (selectedOption === "rename") handleReportRename();
                if (selectedOption === "move") handleReportMove();
              }}
            >
              {selectedOption === "rename" ? "rename" : "Move"}
            </Button>
            {/* <Button color="orange" onClick={() => {}}>
              cancel
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>

      {isLoading && !hasError && <LoadingComponent message="Fetching Data" />}
      {!isLoading && hasError && <HasError />}
      {!isLoading && !hasError && (
        <div className="__mcg_container">
          <div className="__title_with_option">
            <div className="__container">
              <div className="__table_snip_container_title" title={title}>
                <div title={title}>
                  {layoutsPassToTemplate
                    ? layoutsPassToTemplate.w > 2
                      ? title
                      : `${title?.slice(0, 14)}...`
                    : title}
                </div>

                {/* {title || "Demo"} */}
              </div>
              <SnippetsHeaderActions
                handleFilterOpening={handleFilterOpeningModal}
                handleWidgetDelete={handleWidgetDelete}
                handleReportReload={getCardData}
                handleModalOpenerForOptions={handleModalOpenerForOptions}
                showFilter={false}
              />
            </div>
          </div>
          <div className="content" style={{ height: "100%" }}>
            {cardData.map((data) => {
              return (
                <Cards
                  title={Object.keys(data)[0]}
                  value={data[Object.keys(data)[0]]}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MicroCardGroup;
