import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

import {
  deleteReport,
  geAvgMonthCompanyCreation,
  geTopMonthCompanyCreation,
  geTotalTaskCreation,
  getAllReportsList,
  getAvgMonthTask,
  getCompanyCreationByDate,
  getCompanyVsRecentData,
  getContactCreationByDay,
  getContactVsRecentData,
  getCreatedLast24,
  getDashboardLists,
  getLastWeekCompanyCreated,
  getLastWeekTotalTaskCount,
  getLowestAmount,
  getMaxDealAmount,
  getPersonWiseClose,
  getPersonWiseOpen,
  getTicketClosedLast24,
  getTicketResolveTime,
  getTicketStatusData,
  getTicketVsMonthData,
  getTopMonthTask,
  getTotalByPerson,
  getTotalBySourceData,
  getTotalClosedTicket,
  getTotalCompanyByPerson,
  getTotalCompanyCreated,
  getTotalOpenTicket,
  getTotalProgressedTicket,
  getTotalTicket,
  reportAssignToDashboard,
} from "src/common/analytics-and-dashboard-api-calls";
import LoadingComponent from "src/common/components/loading/Loading";
import HasError from "src/common/components/hasError/HasError";
import {
  IWidgetInfo,
  // IReportListResponse,
  analyticsEndpoints,
} from "src/common/types/analytics.types";
import {
  getDaysAgoFromFormattedDate,
  getFormattedDate,
  getLastNDaysDate,
} from "src/common/helpers/CustomDateHelper";
import "./reportTable.scss";
import {
  columnsForCompanyVsRecent,
  columnsForContactVsRecent,
} from "../GraphANdChartsSnippets/Table/helpers/columns";
import FilterModal from "../FilterModals/FilterModal";
import { DynamicTable } from "../GraphANdChartsSnippets/Table/Table";
import {
  Button,
  Col,
  Divider,
  Icon,
  Input,
  InputGroup,
  Modal,
  Row,
  SelectPicker,
} from "rsuite";
import toast from "react-hot-toast";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      borderRadius: "0px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
};

interface IColumnsForReportTableRow {
  widget_name: string;
  widget_title: string;
  dashboardId: string;
  ownerId: string;
  created_datetime: string;
  widget_endpoint: string;
  description: string;
  date_range: string;
  id: number;
  owner: string;
  dashboard_name: string;
  assigned: string;
}

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const ReportTable: React.FC = () => {
  const [reportListData, setReportListData] = useState<any>([]);
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashBoard, setSelectedDashboard] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentContent, setCurrentContent] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentEndpoint, setCurrentEndpoint] = useState("");
  const [selectedReportToTransfer, setSelectedReportToTransfer] = useState<
    null | number | string
  >(null);
  const [columns, setColumns] = useState<any>([]);
  const [chartData, setChartData] = useState<any>({});
  const [tableData, setTableData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState("30");
  const [searchText, setSearchText] = useState("");
  const [assignState, setAssignState] = useState("");
  const [ownState] = useState("");
  const [filteredList, setFilteredList] = useState<any>([]);
  const [allowDefaultTable, setAllowDefaultTable] = useState<boolean>(false);
  const [showChangeAccessModal, setShowChangeAccessModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [selectedWidget, setSelectedWidget] = useState<IWidgetInfo & any>({});

  const handleFilterOpeningModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleDeletingReport = async (id: number) => {
    setIsDeleting(true);
    const response = await deleteReport(id);
    if (!response.error) {
      getAllAvailableReports();
      toast.success("Dashboard has been deleted.");
      setIsDeleteModalOpen(false);
      setIsLoading(false);
      setIsDeleting(false);
    } else {
      setIsLoading(false);
      toast.error("Failed to delete selected report");
      setIsDeleting(false);
    }
  };

  const fetchData = async (filters?: string, endPoint?: string) => {
    if (endPoint) {
      setCurrentEndpoint(endPoint);
    }

    if (filters) {
      setRangeValue(getDaysAgoFromFormattedDate(filters));
    }

    if (endPoint === analyticsEndpoints.contact_vs_recent) {
      setColumns(columnsForContactVsRecent);
      const response = await getContactVsRecentData(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setTableData(response.data.values);
      }
    }

    if (endPoint === analyticsEndpoints.company_vs_recent) {
      setColumns(columnsForCompanyVsRecent);
      const response = await getCompanyVsRecentData(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setTableData(response.data.values);
      }
    }

    if (endPoint === analyticsEndpoints.contact_vs_time) {
      const response = await getContactCreationByDay(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.company_vs_time) {
      const response = await getCompanyCreationByDate(filters);
      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TICKET_VS_MONTH) {
      const response = await getTicketVsMonthData(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.contact_creation_total_by_source) {
      const response = await getTotalBySourceData(filters);
      if (response && response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.contact_creation_total_by_person) {
      const response = await getTotalByPerson(filters);
      if (response && response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.company_creation_total_by_person) {
      const response = await getTotalCompanyByPerson(filters);
      if (response && response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TICKET_STATUS) {
      const response = await getTicketStatusData(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.CREATED_LAST_24H) {
      const response = await getCreatedLast24(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.CLOSED_LAST_24H) {
      const response = await getTicketClosedLast24(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOTAL_COMPANY_CREATED) {
      const response = await getTotalCompanyCreated(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.LAST_WEEK_COMPANY_CREATED) {
      const response = await getLastWeekCompanyCreated(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOP_MONTH_COMPANY_CREATION) {
      const response = await geTopMonthCompanyCreation(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.AVERAGE_COMPANY_CREATION_PER_MONTH) {
      const response = await geAvgMonthCompanyCreation(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOTAL_TASK_COUNT) {
      const response = await geTotalTaskCreation(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.LAST_WEEK_TOTAL_TASK_COUNT) {
      const response = await getLastWeekTotalTaskCount(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TOP_MONTH_TASK_ANALYTICS) {
      const response = await getTopMonthTask(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.AVG_MONTH_TASK_ANALYTICS_CARDS) {
      const response = await getAvgMonthTask(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_MAX_DEAL_AMOUNT_MONTH) {
      const response = await getMaxDealAmount(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_LOWEST_DEAL_AMOUNT_MONTH) {
      const response = await getLowestAmount(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_TICKET) {
      const response = await getTotalTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_OPEN_TICKET) {
      const response = await getTotalOpenTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_CLOSE_TICKET) {
      const response = await getTotalClosedTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.GET_TOTAL_TICKET_IN_PROGRESS) {
      const response = await getTotalProgressedTicket(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.AVERAGE_TICKET_RESOLVE_TIME) {
      const response = await getTicketResolveTime(filters);

      if (response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TICKET_PERSON_WISE_OPEN) {
      const response = await getPersonWiseOpen(filters);
      if (response && response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }

    if (endPoint === analyticsEndpoints.TICKET_PERSON_WISE_CLOSE) {
      const response = await getPersonWiseClose(filters);
      if (response && response.error === true) {
        setHasError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setChartData(response);
      }
    }
  };

  const fetchFilterData = (filters: string) => {
    setIsLoading(true);
    fetchData(filters, currentEndpoint);
  };

  const HandleModal = async (
    content: string,
    endPoint: string,
    description: string,
    filter: string
  ) => {
    console.log("description", description);
    await fetchData(getLastNDaysDate(filter), endPoint);
    handleFilterOpeningModal();
    setCurrentContent(content);
    setCurrentDescription(description);
  };

  const columnsForReportTable = [
    {
      name: "Name",
      selector: (row: IColumnsForReportTableRow) => (
        <>
          <div
            className="report-list-table-header"
            onClick={async () => {
              console.log(row);
              setSelectedWidget(row);
              await HandleModal(
                row.widget_name,
                row.widget_endpoint,
                row.description,
                row.date_range
              );
            }}
          >
            {row.widget_title}
          </div>
        </>
      ),
      width: "300px",
    },
    {
      name: "Dashboards",
      selector: (row: IColumnsForReportTableRow) =>
        row.dashboard_name ? row.dashboard_name : "-",
      width: "250px",
    },
    {
      name: "Owner",
      selector: (row: IColumnsForReportTableRow) =>
        row.owner ? (
          <span className="ml-1"> {row.owner} </span>
        ) : (
          <span className="ml-2">-</span>
        ),
      width: "180px",
    },
    {
      name: "Assigned",
      selector: (row: IColumnsForReportTableRow) =>
        row.owner ? (
          <span className="ml-1"> {row.assigned} </span>
        ) : (
          <span className="ml-2">-</span>
        ),
      width: "180px",
    },
    {
      name: "Created At",
      selector: (row: IColumnsForReportTableRow) =>
        row.created_datetime ? getFormattedDate(row.created_datetime) : "-",
      width: "150px",
    },
    {
      name: "Actions",
      selector: (row: IColumnsForReportTableRow) => (
        <div>
          <Button
            appearance="ghost"
            className="ml-1"
            onClick={() => {
              setShowChangeAccessModal(true);
              setSelectedReportToTransfer(row.id);
            }}
          >
            {row.dashboard_name ? "Move to dashboard" : "Add to dashboard"}
          </Button>
        </div>
      ),
      width: "250px",
    },
    {
      name: "Delete",
      selector: (row: IColumnsForReportTableRow) => (
        <div>
          <Button
            appearance="ghost"
            className="ml-1"
            color="red"
            disabled={isDeleting}
            onClick={() => {
              setSelectedRowId(row.id);
              setIsDeleteModalOpen(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
      width: "150px",
    },
  ];

  const defaultTableData = [
    {
      id: 0,
      created_datetime: "",
      widget_name: "",
      widget_format: "",
      widget_summary: "",
      widget_endpoint: "",
      is_active: true,
      owner: "",
      widget_id: 0,
      widget_tags: "",
      widget_title: "",
      widget_description: "",
      date_range: null,
      dashboard_name: "",
      view_edit: 0,
      assigned: "",
    },
  ];

  // const flattenedDataForTable = (data: IReportListResponse[]) => {
  //   return data.map((item: IReportListResponse) => {
  //     const widgetInfo = item.Widgets;
  //     const associationInfo = item.UserWidgetAssociation;

  //     return {
  //       content: widgetInfo.widget_name,
  //       name: widgetInfo.widget_title,
  //       dashboardId: associationInfo.dashboard_id,
  //       ownerId: associationInfo.user_id,
  //       createdAt: associationInfo.created_datetime,
  //       endpoint: widgetInfo.widget_endpoint,
  //       description: widgetInfo.widget_description,
  //       filter: associationInfo.user_preference.from,
  //       _id: associationInfo.id,
  //     };
  //   });
  // };

  const handleAssignChange = (value: string) => {
    setAssignState(value);
  };

  const assignData = ["Owner only", "Everyone"].map((item) => ({
    label: item,
    value: item,
  }));

  const getAllAvailableReports = async () => {
    setIsLoading(true);
    const response = await getAllReportsList();

    if (response && response.error === true) {
      setHasError(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      // const _data = flattenedDataForTable(response.data);
      setReportListData(response.data.response);
      setFilteredList(
        !allowDefaultTable ? response.data?.response : defaultTableData
      );
      if (response.data?.response.length < 1) {
        setAllowDefaultTable(true);
      }
    }
  };

  const getDashboardInfo = async () => {
    const response = await getDashboardLists();
    if (response.data?.length > 0) {
      let modified: any = [];
      response.data.map((data: any) => {
        return modified.push({
          value: data.id,
          label: data.dashboard_name,
        });
      });
      setDashboards(modified);
    }
  };

  const handleReportAssignToDashboard = async () => {
    setIsDisable(true);
    const response = await reportAssignToDashboard(
      selectedDashBoard,
      selectedReportToTransfer
    );

    if (response && response.error === true) {
      setIsDisable(false);
      toast.error("Service is currently down");
    } else {
      setShowChangeAccessModal(false);
      setIsDisable(false);
      getAllAvailableReports();
      toast.success("Successfully Assigned");
    }
  };

  useEffect(() => {
    if (assignState === null) {
      setFilteredList(reportListData);
    } else if (ownState === null) {
      setFilteredList(reportListData);
    } else {
      const filtered = reportListData.filter(
        (data: any) =>
          data?.assigned?.toLowerCase().includes(assignState?.toLowerCase()) &&
          data?.owner?.toLowerCase().includes(ownState?.toLowerCase())
      );
      setFilteredList(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignState, ownState]);

  useEffect(() => {
    const filtered = reportListData.filter((dataIndex: any) =>
      dataIndex?.widget_title?.toLowerCase().includes(searchText?.toLowerCase())
    );
    setFilteredList(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (showChangeAccessModal) {
      getDashboardInfo();
    }
  }, [showChangeAccessModal]);

  useEffect(() => {
    getAllAvailableReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        {/* modal container */}
        <Modal
          className="__generic_modal_v2"
          autoFocus
          show={showChangeAccessModal}
          onHide={() => {
            setShowChangeAccessModal(false);
            setSelectedDashboard(undefined);
          }}
          backdrop={"static"}
          size="lg"
        >
          <Modal.Header className="modal-header-custom">
            <Modal.Title>{`Add to dashboard`}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "14px 40px 1px", minHeight: "280px" }}>
            <div className="title_for_rt">Select existing dashboard</div>
            <SelectPicker
              style={{
                marginTop: "15px",
                width: "320px",
              }}
              data={dashboards}
              cleanable={false}
              onSelect={(value) => {
                setSelectedDashboard(value);
              }}
            />
            <div className="message-container mt-3">
              Adding reports to a dashboard will change who has access to it.
            </div>

            <Divider />
            <div className="actions" style={{ display: "flex", gap: "10px" }}>
              <Button
                color="green"
                appearance="default"
                disabled={selectedDashBoard === undefined || isDisable}
                onClick={handleReportAssignToDashboard}
              >
                Add to dashboard{" "}
              </Button>
              <Button
                color="orange"
                onClick={() => {
                  setShowChangeAccessModal(false);
                  setSelectedDashboard(undefined);
                }}
              >
                cancel
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>

      <>
        <Modal
          className="__generic_modal"
          backdrop={"static"}
          show={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
        >
          <Modal.Header className="modal-header-custom">
            <Modal.Title className="title">Delete Report</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              overflow: "auto",
              maxHeight: "100px !important",
              padding: "25px",
              marginTop: 0,
            }}
          >
            {/* <h4>Are You Sure?</h4> */}
            <p>Are you sure you want to delete this report?</p>
          </Modal.Body>
          <Modal.Footer style={{ padding: "20px" }}>
            <Button
              color="red"
              appearance="primary"
              disabled={isDeleting}
              onClick={() => handleDeletingReport(selectedRowId)}
            >
              Delete
            </Button>

            <Button
              color="green"
              appearance="primary"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      {isFilterModalOpen &&
        (currentContent === "contact_vs_recent" ||
          currentContent === "company_vs_recent") && (
          <FilterModal
            isFilterModalOpen={isFilterModalOpen}
            handleFilterOpening={handleFilterOpeningModal}
            title={currentDescription}
            chart={<DynamicTable values={tableData} columns={columns} />}
            isLoading={isLoading}
            fetchFilterData={fetchFilterData}
            rangeValue={rangeValue}
            widgetData={selectedWidget}
          />
        )}

      {isFilterModalOpen &&
        (currentContent === "contact_vs_time" ||
          currentContent === "company_vs_time" ||
          currentContent === "ticket_vs_month") && (
          <FilterModal
            isFilterModalOpen={isFilterModalOpen}
            handleFilterOpening={handleFilterOpeningModal}
            title={currentDescription}
            fetchFilterData={fetchFilterData}
            isLoading={isLoading}
            chart={
              <Bar
                data={
                  {
                    labels:
                      chartData.data?.labels?.length > 0
                        ? chartData.data.labels.map((timestamp: any) => {
                            const date = new Date(timestamp);
                            const year = date.getUTCFullYear();
                            const month = String(
                              date.getUTCMonth() + 1
                            ).padStart(2, "0");
                            const day = String(date.getUTCDate()).padStart(
                              2,
                              "0"
                            );
                            return `${year}-${month}-${day}`;
                          })
                        : [],
                    datasets: [
                      {
                        label: "Roboket Dataset",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 1,
                        data:
                          chartData.data?.data?.length > 0
                            ? chartData.data?.data
                            : [],
                      },
                    ],
                  } as any
                }
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                  },
                }}
              />
            }
            rangeValue={rangeValue}
            widgetData={selectedWidget}
          />
        )}

      {isFilterModalOpen &&
        (currentContent === "contact_creation_total_by_source" ||
          currentContent === "get_stage_ticket_count" ||
          currentContent === "deals_in_a_stage_count" ||
          currentContent === "contact_creation_total_by_person" ||
          currentContent === "company_creation_total_by_person" ||
          currentContent === "ticket_person_wise_open" ||
          currentContent === "ticket_person_wise_close" ||
          currentContent === "company_creation_total_by_person") && (
          <FilterModal
            isFilterModalOpen={isFilterModalOpen}
            handleFilterOpening={handleFilterOpeningModal}
            title={currentDescription}
            fetchFilterData={fetchFilterData}
            isLoading={isLoading}
            chart={
              <Bar
                data={
                  {
                    labels:
                      chartData.data?.labels?.length > 0
                        ? chartData.data.labels
                        : [],
                    datasets: [
                      {
                        label: "Roboket Dataset",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 1,
                        data:
                          chartData.data?.data?.length > 0
                            ? chartData.data?.data
                            : [],
                      },
                    ],
                  } as any
                }
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                  },
                }}
              />
            }
            rangeValue={rangeValue}
            widgetData={selectedWidget}
          />
        )}

      {isFilterModalOpen && currentContent === "ticket_status" && (
        <FilterModal
          isFilterModalOpen={isFilterModalOpen}
          handleFilterOpening={handleFilterOpeningModal}
          title={currentDescription}
          chart={
            <div style={{ maxHeight: "600px" }}>
              <Pie
                data={{
                  labels:
                    chartData.data?.labels.length > 0
                      ? chartData.data?.labels
                      : [],
                  datasets: [
                    {
                      label: "Roboket Dataset",
                      data:
                        chartData.data?.data?.length > 0
                          ? chartData.data?.data
                          : [],
                      backgroundColor:
                        chartData.data?.backgroundColor.length > 0
                          ? chartData.data?.backgroundColor
                          : [],
                      hoverOffset: 4,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          }
          isLoading={isLoading}
          fetchFilterData={fetchFilterData}
          rangeValue={rangeValue}
          widgetData={selectedWidget}
        />
      )}

      {isFilterModalOpen &&
        (currentContent === "get_created_last_24h" ||
          currentContent === "total_company_created" ||
          currentContent === "last_week_company_created" ||
          currentContent === "top_month_company_creation" ||
          currentContent === "average_company_creation_per_month" ||
          currentContent === "total_task_count" ||
          currentContent === "last_week_total_task_count" ||
          currentContent === "top_month_task_analytics" ||
          currentContent === "get_max_deal_amount_month" ||
          currentContent === "avg_month_task_analytics_cards" ||
          currentContent === "get_lowest_deal_amount_month" ||
          currentContent === "month_wise_deals_amount" ||
          currentContent === "get_total_ticket" ||
          currentContent === "get_total_open_ticket" ||
          currentContent === "get_total_close_ticket" ||
          currentContent === "get_total_ticket_in_progress" ||
          currentContent === "average_ticket_resolve_time" ||
          currentContent === "get_close_last_24h") && (
          <FilterModal
            isFilterModalOpen={isFilterModalOpen}
            handleFilterOpening={handleFilterOpeningModal}
            title={currentDescription}
            chart={
              <div style={{ maxHeight: "600px" }}>
                <div
                  className="mt-4"
                  style={{
                    fontSize: "50px",
                    fontWeight: "500",
                    textAlign: "center",
                    color: "rgb(29, 61, 112)",
                  }}
                >
                  {chartData &&
                    String(
                      chartData?.data["" + Object.keys(chartData?.data)[0]]
                    )}
                </div>
              </div>
            }
            isLoading={isLoading}
            fetchFilterData={fetchFilterData}
            rangeValue={rangeValue}
            widgetData={selectedWidget}
          />
        )}

      <div className="__ReportTable_container">
        <div style={{ padding: "16px 0px" }}>
          <Row>
            <Col lg={4} md={8} sm={8}>
              <SelectPicker
                data={assignData}
                searchable={false}
                placeholder="Assigned"
                onChange={handleAssignChange}
                style={{ width: "100%" }}
              />
            </Col>

            {/* <Col lg={3} md={5} sm={5}>
              <SelectPicker
                data={ownData}
                searchable={false}
                placeholder="Owner"
                onChange={handleOwnChange}
                style={{ width: 224 }}
              />
            </Col> */}
            <Col lg={2} md={2} sm={2}></Col>
            <Col lg={6} md={2} sm={2}></Col>
            <Col lg={4} md={2} sm={2}></Col>
            <Col lg={4} md={2} sm={2}></Col>

            <Col lg={4} md={8} sm={8}>
              <InputGroup className="searchInput">
                <Input
                  placeholder="Search"
                  value={searchText}
                  onChange={(value) => setSearchText(value)}
                />
                <InputGroup.Button>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </div>

        {isLoading && !hasError && <LoadingComponent message="Fetching Data" />}
        {!isLoading && hasError && <HasError />}
        {!isLoading && !hasError && (
          <div
            className={`${
              allowDefaultTable
                ? "reportTable-default-area-style"
                : "reportTable-area-style"
            }`}
          >
            <DataTable
              columns={columnsForReportTable as any}
              data={filteredList}
              persistTableHead={true}
              selectableRows={false}
              pagination={true}
              customStyles={customStyles}
            />
            {/* {allowDefaultTable && (
              <p className="noData-msg">There are no records to display.</p>
            )} */}
          </div>
        )}
      </div>
    </>
  );
};

export default ReportTable;
