import axios from "./axios-config";
import { ApiRoutes, userOnboardUrls } from "./routes";


export async function GetActiveAccount(userId: any) {
  const params = {
    user_id: userId,
  };
  const result = await axios.get(ApiRoutes.GET_LAST_ACTIVE_ACCOUNT, {
    params: params,
  });
  return result;
}

export const _getMarketingProgressData = async () => {
  const response = await axios
    .get(userOnboardUrls.marketing)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
 
  return response;
};
 
export const _getSalesProgressData = async () => {
  const response = await axios
    .get(userOnboardUrls.sales)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
 
  return response;
};

export const _getCustomerSupportProgressData = async () => {
  const response = await axios
    .get(userOnboardUrls.customer_support)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
 
  return response;
};
 
export const _getSetUpProgressData = async () => {
  const response = await axios
    .get(userOnboardUrls.setup)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
 
  return response;
};


export async function GetUserById(id: string) {
  const result = await axios.get(ApiRoutes.GET_USER_BY_ID + `${id}`);
  return result;
}

export async function CreateUser(formData: any) {
  const result = await axios.post(ApiRoutes.CREATE_USER, formData);
  return result;
}

export async function VerifyUser(token: any) {
  const result = await axios.put(ApiRoutes.VERIFY_USER, null, {
    params: token,
  });
  return result;
}

export async function GetIndustryList() {
  const result = await axios.get(ApiRoutes.GET_INDUSTRY_LIST);
  return result;
}

//For Account
export async function GetAllAccounts(userId: any) {
  const result = await axios.get(ApiRoutes.GET_ALL_ACCOUNTS + userId);
  return result;
}

export async function UpdateActiveAccount(userId: string, accountId: string) {
  const params = {
    user_id: userId,
    "account-id": accountId,
  };
  const result = await axios.put(ApiRoutes.UPDATE_ACTIVE_ACCOUNT, null, {
    params: params,
  });
  return result;
}

//For Contact
export async function GetContactsList(userId: string) {
  try {
    const params: any = {
      "user-id": userId,
    };
    const result = await axios.get(ApiRoutes.GET_CONTACTS_LIST, {
      params: params,
    });
    if (result.data.found) {
      return result.data.data;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function GetContactsListByAccountId(
  accountId: number,
  includeContact: boolean,
  ignoreFbContact?: boolean
) {
  try {
    const params: any = {
      include_contact: includeContact,
      ignore_fb_email_contacts: ignoreFbContact,
    };
    const result = await axios.get(
      ApiRoutes.GET_CONTACTS_LIST_BY_ACCOUNT + `${accountId}`,
      { params: params }
    );
    if (result.data) {
      return result.data;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function GetGroups(
  accountId: number,
  isContactCountShow: boolean,
  ignoreFbContact?: boolean
) {
  try {
    const params = {
      include_contact_count: isContactCountShow,
      ignore_fb_email_contacts: ignoreFbContact,
    };
    const result = await axios.get(ApiRoutes.GET_GROUPS + accountId, {
      params: params,
    });
    return result.data;
  } catch (error) {
    return [];
  }
}

export async function GetContactsOfGroup(
  groupId: number,
  isShowContacts: boolean
) {
  try {
    const params = {
      include_contacts: isShowContacts,
    };
    const result = await axios.get(
      ApiRoutes.CONTACT_GROUP + `${groupId}/contacts`,
      { params: params }
    );
    return result.data;
  } catch (error) {
    return error;
  }
}
